<template>
    <div class="ws-specific-availabilities">
        <p
            v-for="(errorMessage, i) in fieldErrors"
            :key="`error-${i}`"
            :class="`${$css('ws-my-2')} ${$css('ws-text-danger')}`"
            v-text="errorMessage"
        ></p>
        <div ref="header" class="ws-specific-availabilities-header">
            <div>
                <strong
                    v-text="$trans('Locatie', 'registration.location')"
                ></strong>
                <select
                    v-model="filter.locationId"
                    :disabled="!!selectedSlot"
                    class="ws-select"
                >
                    <option
                        value=""
                        v-text="
                            $trans(
                                'Alle locaties',
                                'registration.specific_abilities.all_locations'
                            )
                        "
                    ></option>
                    <option
                        v-for="{
                            text,
                            value: val,
                            disabled,
                        } in filterOptions.locations"
                        :key="val"
                        :value="val"
                        :disabled="disabled"
                        v-text="text"
                    ></option>
                </select>
            </div>
            <div>
                <strong
                    v-text="
                        $trans(
                            'Startperiode',
                            'registration.specific_abilities.start_period'
                        )
                    "
                ></strong>
                <select
                    v-model="filter.startMonth"
                    :disabled="!!selectedSlot"
                    class="ws-select"
                >
                    <option
                        value=""
                        v-text="
                            $trans(
                                'Alle startperiodes',
                                'registration.specific_abilities.all_start_months'
                            )
                        "
                    ></option>
                    <option
                        v-for="{
                            text,
                            value: val,
                            disabled,
                        } in filterOptions.startMonths"
                        :key="val"
                        :value="val"
                        :disabled="disabled"
                        v-text="text"
                    ></option>
                </select>
            </div>
            <div>
                <strong
                    v-text="$trans('Lespakket', 'registration.curriculum')"
                ></strong>
                <select
                    v-model="filter.curriculumId"
                    :disabled="!!selectedSlot"
                    class="ws-select"
                >
                    <option
                        value=""
                        v-text="
                            $trans(
                                'Alle pakketten',
                                'registration.specific_abilities.all_curricula'
                            )
                        "
                    ></option>
                    <option
                        v-for="{
                            text,
                            value: val,
                            disabled,
                        } in filterOptions.curricula"
                        :key="val"
                        :value="val"
                        :disabled="disabled"
                        v-text="text"
                    ></option>
                </select>
            </div>
            <div>
                <strong
                    v-text="
                        $trans(
                            'Lesdag',
                            'registration.specific_abilities.lesson_day'
                        )
                    "
                ></strong>
                <select
                    v-model="filter.dayNr"
                    :disabled="!!selectedSlot"
                    class="ws-select"
                >
                    <option
                        value=""
                        v-text="
                            $trans(
                                'Alle dagen',
                                'registration.specific_abilities.all_days'
                            )
                        "
                    ></option>
                    <option
                        v-for="{
                            text,
                            value: val,
                            disabled,
                        } in filterOptions.days"
                        :key="val"
                        :value="val"
                        :disabled="disabled"
                        v-text="text"
                    ></option>
                </select>
            </div>
            <div>
                <strong
                    v-text="
                        $trans(
                            'Lestijden',
                            'registration.specific_abilities.lesson_times'
                        )
                    "
                ></strong>
            </div>
            <div>
                <strong
                    v-text="
                        $trans(
                            'Beschikbaarheid',
                            'registration.specific_abilities.availability'
                        )
                    "
                ></strong>
            </div>
        </div>
        <div
            v-for="lessonSlotRow in filteredLessonSlotRows"
            :key="lessonSlotRow.id"
            class="ws-specific-availabilities-row"
        >
            <div v-text="lessonSlotRow.location.name"></div>
            <div v-text="lessonSlotRow.startMonth"></div>
            <div v-text="lessonSlotRow.curriculum.name"></div>
            <div v-text="lessonSlotRow.dayLabel"></div>
            <div v-text="lessonSlotRow.timeLabel"></div>
            <div>
                <div class="ws-occupancy">
                    <OccupancyIcon
                        :level="lessonSlotRow.occupancyClass"
                        class="ws-occupancy-icon"
                    />
                    <span
                        class="ws-occupancy-text"
                        v-text="lessonSlotRow.occupancyTitle"
                    ></span>
                </div>
                <div
                    v-if="
                        selectedSlot?.id !== lessonSlotRow.id &&
                        lessonSlotRow.occupancyClass !== 'full'
                    "
                    class="ws-specific-availabilities-select"
                >
                    <a
                        @click="selectStartLessonSlot(lessonSlotRow)"
                        v-text="
                            $trans(
                                'Selecteer les',
                                'registration.specific_abilities.select'
                            )
                        "
                    ></a>
                </div>
            </div>
        </div>
        <div v-if="filteredLessonSlotRows.length === 0">
            <i
                v-text="
                    $trans(
                        'Geen instroomperiodes gevonden. Neem contact op met de zwemschool!',
                        'registration.specific_abilities.no_dates_contact_school'
                    )
                "
            ></i>
        </div>
        <div v-if="selectedSlot" class="ws-specific-availabilities-deselect">
            <a
                @click="selectStartLessonSlot(null)"
                v-text="
                    $trans(
                        'Andere les selecteren',
                        'registration.specific_abilities.select_other'
                    )
                "
            ></a>
        </div>
    </div>
</template>
<script>
import { dayLabels, timeLabel } from '@/types/PupilLessonSlot';
import moment from 'moment';
import OccupancyIcon from '@/components/StartLessonSlots/OccupancyIcon.vue';

const defaultFilters = () => ({
    locationId: '',
    startMonth: '',
    curriculumId: '',
    dayNr: '',
});

export default {
    name: 'SpecificAvailabilities',

    components: { OccupancyIcon },

    props: {
        value: Object,
        form: Object,
        errors: Object,
    },

    data() {
        return {
            dayLabels: dayLabels(this),
            filter: defaultFilters(),
            selectedSlot: null,
            occupancyClasses: ['min', 'min', 'mid', 'max', 'full'],
            occupancyTitles: [
                this.$trans(
                    'Beschikbaar',
                    'registration.specific_abilities.occupancy.min'
                ),
                this.$trans(
                    'Beschikbaar',
                    'registration.specific_abilities.occupancy.min'
                ),
                this.$trans(
                    'Vrije plaatsen',
                    'registration.specific_abilities.occupancy.mid'
                ),
                this.$trans(
                    'Bijna vol!',
                    'registration.specific_abilities.occupancy.max'
                ),
                this.$trans(
                    'Vol!',
                    'registration.specific_abilities.occupancy.full'
                ),
            ],
        };
    },

    computed: {
        formStartLessonSlotConfigs() {
            return this.form.formStartLessonSlotConfigs.filter(
                fsls => fsls.mode === 'specific_time_slots'
            );
        },
        filteredLessonSlotRows() {
            return this.filterLessonSlotRows(
                this.lessonSlotRows,
                this.filter,
                this.selectedSlot?.id
            );
        },
        lessonSlotRows() {
            const now = moment();
            return this.form.formStartLessonSlots
                .map(fsls => {
                    const location = this.getLocation(fsls.location_id);
                    const curriculum = this.getCurriculum(fsls.curriculum_id);
                    const course = this.getCourseFromCurriculum(
                        curriculum,
                        fsls.course_id
                    );
                    const hasFormStartLessonSlotConfig =
                        this.form.formStartLessonSlotConfigs.some(
                            fslsc =>
                                fslsc.course_id === fsls.course_id &&
                                fslsc.location_id === fsls.location_id &&
                                fslsc.day === fsls.day
                        );
                    const dayNr = `day${fsls.day}`;
                    const occupancyIndex =
                        fsls.max_pupils === 0
                            ? 4
                            : Math.min(
                                  4,
                                  Math.floor(
                                      (fsls.waiting_pupils_count /
                                          fsls.max_pupils) *
                                          4
                                  )
                              );
                    return {
                        hasFormStartLessonSlotConfig,
                        location,
                        curriculum,
                        course,
                        occupancyClass: this.occupancyClasses[occupancyIndex],
                        occupancyTitle: this.occupancyTitles[occupancyIndex],
                        dayNr,
                        dayLabel: this.dayLabels[dayNr],
                        timeLabel: timeLabel(fsls),
                        startMonth: moment(fsls.start_date).format('MMMM YYYY'),
                        ...fsls,
                        start_date: moment(fsls.start_date).format(
                            'YYYY-MM-DD HH:mm:ss'
                        ),
                    };
                })
                .filter(
                    lsr =>
                        !!lsr.hasFormStartLessonSlotConfig &&
                        moment(lsr.start_date).endOf('month') > now &&
                        !lsr.settings.hidden
                )
                .sort((a, b) => {
                    if (a.location.name !== b.location.name) {
                        return a.location.name > b.location.name ? 1 : -1;
                    }
                    if (a.start_date !== b.start_date) {
                        return a.start_date > b.start_date ? 1 : -1;
                    }
                    if (a.dayNr !== b.dayNr) {
                        return a.dayNr > b.dayNr ? 1 : -1;
                    }
                    return a.time_from > b.time_from ? 1 : -1;
                });
        },
        filterOptions() {
            const locationOptions = {};
            const startMonthOptions = {};
            const curriculumOptions = {};
            const dayOptions = {};
            this.lessonSlotRows.forEach(lsr => {
                locationOptions[lsr.location.id] = lsr.location.name;
                startMonthOptions[lsr.startMonth] = lsr.startMonth;
                curriculumOptions[lsr.curriculum.id] = lsr.curriculum.name;
                dayOptions[lsr.dayNr] = this.dayLabels[lsr.dayNr];
            });
            return {
                locations: Object.entries(locationOptions).map(
                    ([value, text]) => ({
                        value,
                        text,
                        disabled: !this.filterLessonSlotRows(
                            this.lessonSlotRows,
                            {
                                ...this.filter,
                                locationId: '',
                            }
                        ).some(lsr => lsr.location.id === value),
                    })
                ),
                startMonths: Object.entries(startMonthOptions).map(
                    ([value, text]) => ({
                        value,
                        text,
                        disabled: !this.filterLessonSlotRows(
                            this.lessonSlotRows,
                            {
                                ...this.filter,
                                startMonth: '',
                            }
                        ).some(lsr => lsr.startMonth === value),
                    })
                ),
                curricula: Object.entries(curriculumOptions).map(
                    ([value, text]) => ({
                        value,
                        text,
                        disabled: !this.filterLessonSlotRows(
                            this.lessonSlotRows,
                            {
                                ...this.filter,
                                curriculumId: '',
                            }
                        ).some(lsr => lsr.curriculum.id === value),
                    })
                ),
                days: Object.entries(dayOptions).map(([value, text]) => ({
                    value,
                    text,
                    disabled: !this.filterLessonSlotRows(this.lessonSlotRows, {
                        ...this.filter,
                        dayNr: '',
                    }).some(lsr => lsr.dayNr === value),
                })),
            };
        },
        fieldErrors() {
            return this.errors['pupils.0.registration.start_date'] || [];
        },
    },

    methods: {
        selectStartLessonSlot(lessonSlotRow) {
            let input;
            if (lessonSlotRow === null) {
                this.filter = defaultFilters();
                input = {
                    ...this.value,
                    start_date: '',
                    pupilStartLessonSlots: { current: [] },
                };
            } else {
                const {
                    curriculum_id,
                    location_id,
                    course_id,
                    day,
                    time_from,
                    time_till,
                    start_date,
                } = lessonSlotRow;
                input = {
                    ...this.value,
                    location_id,
                    curriculum_id,
                    start_date,
                    pupilStartLessonSlots: {
                        create: [
                            {
                                curriculum_id,
                                course_id,
                                location_id,
                                mode: 'specific_time_slots',
                                day,
                                time_from,
                                time_till,
                                start_date,
                            },
                        ],
                        current: [],
                    },
                };
                if (this.$refs.header) {
                    this.$refs.header.scrollIntoView({ behavior: 'smooth' });
                }
            }
            this.$emit('input', input);
            this.selectedSlot = lessonSlotRow;
        },
        getLocation(location_id) {
            return this.form.locations.find(l => l.id === location_id);
        },
        getCurriculum(curriculum_id) {
            return this.form.curricula.find(c => c.id === curriculum_id);
        },
        getCourseFromCurriculum(curriculum, course_id) {
            return curriculum.courses.find(c => c.course.id === course_id)
                .course;
        },
        filterLessonSlotRows(lessonSlotRows, filter, selectedSlotKey = null) {
            return lessonSlotRows.filter(
                lsr =>
                    (!selectedSlotKey || lsr.id === selectedSlotKey) &&
                    (!filter.locationId ||
                        lsr.location.id === filter.locationId) &&
                    (!filter.curriculumId ||
                        lsr.curriculum.id === filter.curriculumId) &&
                    (!filter.dayNr || lsr.dayNr === filter.dayNr) &&
                    (!filter.startMonth || lsr.startMonth === filter.startMonth)
            );
        },
    },
};
</script>
<style>
.ws-specific-availabilities-row,
.ws-specific-availabilities-header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: var(--grid-column-gap-mobile);
}

.ws-specific-availabilities-header {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    padding: 0.75rem 0.5rem 0.5rem 0.5rem;
}

.ws-specific-availabilities-header > div {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    align-content: space-between;
}

.ws-specific-availabilities-header .ws-select:disabled,
.ws-specific-availabilities-header .ws-select option:disabled {
    background-color: var(--input-disabled-bg-color);
}

.ws-specific-availabilities-row {
    padding: 0.5rem 0.5rem 0 0.5rem;
    margin-bottom: 0.5rem;
}

.ws-specific-availabilities-row {
    align-items: center;
}

.ws-specific-availabilities-row + .ws-specific-availabilities-row {
    border-top: 1px solid var(--border-color);
}

.ws-occupancy {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.25rem;
}

.ws-occupancy .ws-occupancy-icon {
    width: 1.2rem;
    flex: none;
}

.ws-occupancy .ws-occupancy-text {
    font-style: italic;
}

.ws-specific-availabilities-select {
    margin-top: 0.25rem;
}

.ws-specific-availabilities-deselect {
    margin-top: 0.5rem;
    padding-right: 0.5rem;
    text-align: right;
}

.ws-specific-availabilities a {
    cursor: pointer;
    color: var(--link-color, #007bff);
}

.ws-specific-availabilities a:hover {
    color: var(--link-hover-color, #0069d9);
}

@media (min-width: 576px) {
    .ws-specific-availabilities-row,
    .ws-specific-availabilities-header {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: var(--grid-column-gap);
    }
}
</style>
