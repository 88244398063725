var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ws-specific-availabilities"},[_vm._l((_vm.fieldErrors),function(errorMessage,i){return _c('p',{key:`error-${i}`,class:`${_vm.$css('ws-my-2')} ${_vm.$css('ws-text-danger')}`,domProps:{"textContent":_vm._s(errorMessage)}})}),_c('div',{ref:"header",staticClass:"ws-specific-availabilities-header"},[_c('div',[_c('strong',{domProps:{"textContent":_vm._s(_vm.$trans('Locatie', 'registration.location'))}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.locationId),expression:"filter.locationId"}],staticClass:"ws-select",attrs:{"disabled":!!_vm.selectedSlot},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filter, "locationId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""},domProps:{"textContent":_vm._s(
                        _vm.$trans(
                            'Alle locaties',
                            'registration.specific_abilities.all_locations'
                        )
                    )}}),_vm._l((_vm.filterOptions.locations),function({
                        text,
                        value: val,
                        disabled,
                    }){return _c('option',{key:val,attrs:{"disabled":disabled},domProps:{"value":val,"textContent":_vm._s(text)}})})],2)]),_c('div',[_c('strong',{domProps:{"textContent":_vm._s(
                    _vm.$trans(
                        'Startperiode',
                        'registration.specific_abilities.start_period'
                    )
                )}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.startMonth),expression:"filter.startMonth"}],staticClass:"ws-select",attrs:{"disabled":!!_vm.selectedSlot},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filter, "startMonth", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""},domProps:{"textContent":_vm._s(
                        _vm.$trans(
                            'Alle startperiodes',
                            'registration.specific_abilities.all_start_months'
                        )
                    )}}),_vm._l((_vm.filterOptions.startMonths),function({
                        text,
                        value: val,
                        disabled,
                    }){return _c('option',{key:val,attrs:{"disabled":disabled},domProps:{"value":val,"textContent":_vm._s(text)}})})],2)]),_c('div',[_c('strong',{domProps:{"textContent":_vm._s(_vm.$trans('Lespakket', 'registration.curriculum'))}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.curriculumId),expression:"filter.curriculumId"}],staticClass:"ws-select",attrs:{"disabled":!!_vm.selectedSlot},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filter, "curriculumId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""},domProps:{"textContent":_vm._s(
                        _vm.$trans(
                            'Alle pakketten',
                            'registration.specific_abilities.all_curricula'
                        )
                    )}}),_vm._l((_vm.filterOptions.curricula),function({
                        text,
                        value: val,
                        disabled,
                    }){return _c('option',{key:val,attrs:{"disabled":disabled},domProps:{"value":val,"textContent":_vm._s(text)}})})],2)]),_c('div',[_c('strong',{domProps:{"textContent":_vm._s(
                    _vm.$trans(
                        'Lesdag',
                        'registration.specific_abilities.lesson_day'
                    )
                )}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.dayNr),expression:"filter.dayNr"}],staticClass:"ws-select",attrs:{"disabled":!!_vm.selectedSlot},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filter, "dayNr", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""},domProps:{"textContent":_vm._s(
                        _vm.$trans(
                            'Alle dagen',
                            'registration.specific_abilities.all_days'
                        )
                    )}}),_vm._l((_vm.filterOptions.days),function({
                        text,
                        value: val,
                        disabled,
                    }){return _c('option',{key:val,attrs:{"disabled":disabled},domProps:{"value":val,"textContent":_vm._s(text)}})})],2)]),_c('div',[_c('strong',{domProps:{"textContent":_vm._s(
                    _vm.$trans(
                        'Lestijden',
                        'registration.specific_abilities.lesson_times'
                    )
                )}})]),_c('div',[_c('strong',{domProps:{"textContent":_vm._s(
                    _vm.$trans(
                        'Beschikbaarheid',
                        'registration.specific_abilities.availability'
                    )
                )}})])]),_vm._l((_vm.filteredLessonSlotRows),function(lessonSlotRow){return _c('div',{key:lessonSlotRow.id,staticClass:"ws-specific-availabilities-row"},[_c('div',{domProps:{"textContent":_vm._s(lessonSlotRow.location.name)}}),_c('div',{domProps:{"textContent":_vm._s(lessonSlotRow.startMonth)}}),_c('div',{domProps:{"textContent":_vm._s(lessonSlotRow.curriculum.name)}}),_c('div',{domProps:{"textContent":_vm._s(lessonSlotRow.dayLabel)}}),_c('div',{domProps:{"textContent":_vm._s(lessonSlotRow.timeLabel)}}),_c('div',[_c('div',{staticClass:"ws-occupancy"},[_c('OccupancyIcon',{staticClass:"ws-occupancy-icon",attrs:{"level":lessonSlotRow.occupancyClass}}),_c('span',{staticClass:"ws-occupancy-text",domProps:{"textContent":_vm._s(lessonSlotRow.occupancyTitle)}})],1),(
                    _vm.selectedSlot?.id !== lessonSlotRow.id &&
                    lessonSlotRow.occupancyClass !== 'full'
                )?_c('div',{staticClass:"ws-specific-availabilities-select"},[_c('a',{domProps:{"textContent":_vm._s(
                        _vm.$trans(
                            'Selecteer les',
                            'registration.specific_abilities.select'
                        )
                    )},on:{"click":function($event){return _vm.selectStartLessonSlot(lessonSlotRow)}}})]):_vm._e()])])}),(_vm.filteredLessonSlotRows.length === 0)?_c('div',[_c('i',{domProps:{"textContent":_vm._s(
                _vm.$trans(
                    'Geen instroomperiodes gevonden. Neem contact op met de zwemschool!',
                    'registration.specific_abilities.no_dates_contact_school'
                )
            )}})]):_vm._e(),(_vm.selectedSlot)?_c('div',{staticClass:"ws-specific-availabilities-deselect"},[_c('a',{domProps:{"textContent":_vm._s(
                _vm.$trans(
                    'Andere les selecteren',
                    'registration.specific_abilities.select_other'
                )
            )},on:{"click":function($event){return _vm.selectStartLessonSlot(null)}}})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }