<template>
    <div :class="level">
        <svg
            v-if="level === 'min'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <!--! Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc. -->
            <defs></defs>
            <path
                class="fa-secondary"
                d="M32 256a224 224 0 1 0 448 0A224 224 0 1 0 32 256zm50.3-8.2c4.5-7.6 14.4-10 22-5.5l106.9 64.1C222.6 295 238.5 288 256 288c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-6.3 .9-12.4 2.6-18.2L87.8 269.7c-7.6-4.5-10-14.4-5.5-22z"
            />
            <path
                class="fa-primary"
                d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 96c35.3 0 64-28.7 64-64s-28.7-64-64-64c-17.5 0-33.4 7-44.9 18.4L104.2 242.3c-7.6-4.5-17.4-2.1-22 5.5s-2.1 17.4 5.5 22l106.8 64.1c-1.7 5.8-2.6 11.9-2.6 18.2c0 35.3 28.7 64 64 64z"
            />
        </svg>
        <svg
            v-if="level === 'mid'"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <!--! Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc. -->
            <defs></defs>
            <path
                class="fa-secondary"
                d="M32 256a224 224 0 1 0 448 0A224 224 0 1 0 32 256zm160 96c0-29.8 20.4-54.9 48-62l0-194c0-8.8 7.2-16 16-16s16 7.2 16 16l0 194c27.6 7.1 48 32.2 48 62c0 35.3-28.7 64-64 64s-64-28.7-64-64z"
            />
            <path
                class="fa-primary"
                d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 96c35.3 0 64-28.7 64-64c0-29.8-20.4-54.9-48-62l0-194c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 194c-27.6 7.1-48 32.2-48 62c0 35.3 28.7 64 64 64z"
            />
        </svg>
        <svg
            v-if="['max', 'full'].includes(level)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <!--! Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc. -->
            <defs></defs>
            <path
                class="fa-secondary"
                d="M32 256a224 224 0 1 0 448 0A224 224 0 1 0 32 256zm160 96c0-35.3 28.7-64 64-64c17.5 0 33.4 7 44.9 18.4l106.9-64.1c7.6-4.5 17.4-2.1 22 5.5s2.1 17.4-5.5 22L317.4 333.8c1.7 5.8 2.6 11.9 2.6 18.2c0 35.3-28.7 64-64 64s-64-28.7-64-64z"
            />
            <path
                class="fa-primary"
                d="M256 480a224 224 0 1 0 0-448 224 224 0 1 0 0 448zM256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zm0 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm0 32c-35.3 0-64-28.7-64-64s28.7-64 64-64c17.5 0 33.4 7 44.9 18.4l106.9-64.1c7.6-4.5 17.4-2.1 22 5.5s2.1 17.4-5.5 22L317.4 333.8c1.7 5.8 2.6 11.9 2.6 18.2c0 35.3-28.7 64-64 64z"
            />
        </svg>
    </div>
</template>
<script>
export default {
    name: 'OccupancyIcon',

    props: {
        level: String,
    },
};
</script>
<style scoped>
.fa-secondary {
    opacity: 0.4;
}

.min svg .fa-secondary {
    fill: var(--occupancy-min-color, green);
}
.mid svg .fa-secondary {
    fill: var(--occupancy-mid-color, orange);
}
.max svg .fa-secondary {
    fill: var(--occupancy-max-color, red);
}
.full svg .fa-secondary {
    fill: var(--occupancy-max-color, red);
    opacity: 0.7;
}
</style>
