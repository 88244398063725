import gql from 'graphql-tag';

export const EMAIL_EXISTS = gql`
    query EmailExists($email: String!) {
        emailExists(email: $email)
    }
`;

export const PREPARE = gql`
    query {
        form: prepareForm {
            id
            name
            text_before
            text_after
            text_conditions
            terms_url
            privacy_url
            curricula {
                id
                name
                courses {
                    _id
                    course {
                        id
                        name
                    }
                }
            }
            subscriptions {
                id
                name
            }
            locations {
                id
                name
                city
            }
            settings {
                bootstrap_css
                show_title
                adult_audience
                button_classes
                transaction_processing
            }
            option_relations {
                location_id
                option_relations {
                    property_id
                    property
                    allowedProperty
                    allowedValues
                }
            }
            location_settings {
                location_id
                start_date_offset_weeks
                start_lesson_slot_mode
                text_availability
            }
            formStartLessonSlotConfigs {
                id
                location_id
                course_id
                mode
                day
                time_from
                time_till
            }
            formStartLessonSlots {
                id
                curriculum_id
                course_id
                location_id
                day
                time_from
                time_till
                start_date
                waiting_pupils_count
                max_pupils
                settings {
                    hidden
                }
            }
            fields {
                type
                custom_field_id
                form_position
                required
                ordering
                customField {
                    field_type
                    options
                    label
                    default_value
                    settings {
                        help
                    }
                }
            }
        }
    }
`;

export const PUPILS_FRAGMENT = gql`
    fragment pupilsPayload on CreateCustomerPayload {
        pupils {
            full_name
            birth_date
            gender
            registration {
                curriculum_id
                course_id
                location_id
                start_date
                notes
            }
            customFieldValues {
                custom_field_id
                value
            }
            subscriptions {
                id
                subscription {
                    id
                    name
                }
            }
        }
        transaction {
            id
            amount
            payment_method
            payment_url
            specifications {
                message
            }
        }
    }
`;

export const ADD_CUSTOMER_PUPIL = gql`
    mutation AddCustomerPupil($input: AddCustomerPupilInput) {
        createCustomer: addCustomerPupil(input: $input) {
            customer {
                user {
                    email
                }
            }
            ...pupilsPayload
        }
    }
    ${PUPILS_FRAGMENT}
`;

export const CREATE_CUSTOMER = gql`
    mutation CreateCustomer($input: CreateCustomerInput) {
        createCustomer(input: $input) {
            customer {
                id
                full_name
                street
                house_number
                zipcode
                city
                country_code
                payment_method
                account_iban_masked
                account_name
                phone_numbers {
                    number
                }
                user {
                    email
                }
                customFieldValues {
                    custom_field_id
                    value
                }
            }
            ...pupilsPayload
        }
    }
    ${PUPILS_FRAGMENT}
`;
