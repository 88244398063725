<template>
    <div>
        <SpecificAvailabilities
            v-if="hasStartLessonSlots"
            :value="value"
            :form="form"
            :errors="errors"
            :class="$css('ws-mb-4')"
            @input="$emit('input', $event)"
        />
        <template v-else>
            <FormRow
                :label="$trans('Locatie', 'registration.location')"
                :errors="errors"
                error-key="location_id"
            >
                <strong v-if="form.locations.length === 1">{{
                    form.locations[0].name
                }}</strong>
                <template v-else>
                    <div v-for="location in form.locations" :key="location.id">
                        <label>
                            <input
                                :value="location.id"
                                v-model="location_id"
                                type="radio"
                                style="margin-right: 5px"
                            />
                            {{ location.name }} - {{ location.city }}
                        </label>
                    </div>
                </template>
            </FormRow>

            <div v-if="value.location_id" :class="$css('ws-grid')">
                <div :class="$css('ws-col-md-1/2')">
                    <FormRow
                        :label="$trans('Lespakket', 'registration.curriculum')"
                        :errors="errors"
                        error-key="curriculum_id"
                        stacked
                    >
                        <select
                            v-if="selectableCurricula.length > 1"
                            v-model="curriculum_id"
                            :class="$css('ws-select')"
                            required
                        >
                            <option
                                value=""
                                hidden="hidden"
                                disabled="disabled"
                                v-text="
                                    $trans(
                                        'Kies een lespakket',
                                        'registration.choose_curriculum'
                                    )
                                "
                            ></option>
                            <option
                                v-for="curriculum in selectableCurricula"
                                :key="curriculum.id"
                                :value="curriculum.id"
                                v-text="curriculum.name"
                            ></option>
                        </select>
                        <strong
                            v-else
                            v-text="selectableCurricula[0].name"
                        ></strong>
                    </FormRow>
                </div>
                <div :class="$css('ws-col-md-1/2')">
                    <FormRow
                        :label="
                            $trans(
                                'Gewenste startdatum',
                                'registration.start_date'
                            )
                        "
                        :errors="errors"
                        error-key="pupils.0.registration.start_date"
                        stacked
                    >
                        <DatePicker
                            v-model="start_date"
                            :min-date="minStartDate"
                            :placeholder="
                                $trans(
                                    'Kies een datum',
                                    'registration.start_date_pick'
                                )
                            "
                        ></DatePicker>
                        <p :class="$css('ws-my-2')">
                            <small
                                v-text="
                                    $trans(
                                        'Dit is een start-indicatie',
                                        'registration.start_date_indication'
                                    )
                                "
                            ></small>
                        </p>
                    </FormRow>
                </div>
            </div>

            <StartLessonSlotEdit
                v-model="pupilStartLessonSlots"
                :pupil-registration="value"
                :form="form"
                :errors="errors"
            />
        </template>

        <FormRow
            v-slot="{ hasErrors }"
            :label="$trans('Bijzonderheden', 'registration.notes')"
            :help="
                $trans(
                    'Geef kort bijzonderheden aan die van invloed kunnen zijn op de zwemlessen',
                    'registration.notes_tip'
                )
            "
            :errors="errors"
            error-key="pupils.0.registration.notes"
        >
            <textarea
                v-model="notes"
                :class="[
                    hasErrors ? $css('ws-is-invalid') : '',
                    $css('ws-textarea'),
                ]"
                cols="30"
                rows="5"
            ></textarea>
            <p
                v-if="notes.length >= 2000"
                :class="`${$css('ws-my-2')} ${$css('ws-text-danger')}`"
                v-text="
                    $trans('Maximum lengte bereikt', 'error.max_length_reached')
                "
            ></p>
        </FormRow>
    </div>
</template>
<script>
import moment from 'moment';
import FormRow from '@/components/FormRow.vue';
import DatePicker from '@/components/DatePicker';
import StartLessonSlotEdit from '@/components/StartLessonSlots/StartLessonSlotEdit';
import {
    findFirstValidCourseId,
    findFirstValidCurriculumId,
} from '@/OptionRelationsHelper';
import SpecificAvailabilities from '@/components/StartLessonSlots/SpecificAvailabilities.vue';

export default {
    name: 'RegistrationForm',

    components: {
        SpecificAvailabilities,
        DatePicker,
        FormRow,
        StartLessonSlotEdit,
    },

    props: {
        value: Object,
        form: Object,
        errors: Object,
        locationRelation: [Object, null],
        locationSettings: Object,
    },

    computed: {
        hasStartLessonSlots() {
            return this.form.formStartLessonSlots.length > 0;
        },
        selectableCurricula() {
            if (this.locationRelation) {
                return this.form.curricula.filter(c => {
                    return this.locationRelation.allowedValues.includes(
                        String(c.id)
                    );
                });
            }
            return this.form.curricula;
        },
        minStartDate() {
            return this.locationSettings
                ? moment()
                      .startOf('day')
                      .add(
                          this.locationSettings.start_date_offset_weeks,
                          'weeks'
                      )
                : moment().startOf('day');
        },
        curriculum_id: {
            get() {
                return this.value.curriculum_id || '';
            },
            set(curriculum_id) {
                this.$emit('input', { ...this.value, curriculum_id });
            },
        },
        location_id: {
            get() {
                return this.value.location_id || '';
            },
            set(location_id) {
                this.$emit('input', { ...this.value, location_id });
            },
        },
        start_date: {
            get() {
                return this.value.start_date;
            },
            set(start_date) {
                this.$emit('input', { ...this.value, start_date });
            },
        },
        pupilStartLessonSlots: {
            get() {
                return this.value.pupilStartLessonSlots;
            },
            set(pupilStartLessonSlots) {
                this.$emit('input', { ...this.value, pupilStartLessonSlots });
            },
        },
        notes: {
            get() {
                return this.value.notes;
            },
            set(notes) {
                this.$emit('input', {
                    ...this.value,
                    notes: notes.substr(0, 2000),
                });
            },
        },
    },

    watch: {
        location_id(location_id) {
            const curriculum_id = findFirstValidCurriculumId(
                this.form,
                location_id,
                this.value.curriculum_id
            );
            if (curriculum_id !== this.value.curriculum_id) {
                this.$emit('input', {
                    ...this.value,
                    curriculum_id,
                });
            }
        },
        curriculum_id(curriculum_id) {
            const course_id = findFirstValidCourseId(
                this.form,
                curriculum_id,
                this.value.course_id
            );
            if (course_id !== this.value.course_id) {
                this.$emit('input', {
                    ...this.value,
                    course_id,
                });
            }
        },
    },
};
</script>
